import React from "react"

import CenterColumn from "../../components/Bulma/CenterColumn"
import ImageMeta from "../../components/ImageMeta"
import MarkdownViewer from "@components/MarkdownViewer"
import Button from "@components/Button/Button"

const Exparel = ({ post }) => {
  if (!post.hasThisSection) return <></>

  return (
    <CenterColumn
      className="home-exparel"
      leftColWidth={4}
      rightColWidth={4}
      centerColumn={
        <div className="column" data-aos="fade-up" data-aos-duration="1200">
          <ImageMeta
            cloudName="nuvolum"
            publicId={post.imageId}
            width="473"
            height="56"
            responsive
          />
          <MarkdownViewer markdown={post.blurb} />
          <Button href={post.button.href} buttonText={post.button.buttonText} />
        </div>
      }
    />
  )
}

export default Exparel
